import React from 'react'
import { Link } from 'gatsby'
import SVGImage from './svgImage'
import ThemeContext from '../context/ThemeContext'

const Navigation = () => (
  <ThemeContext.Consumer>
    {theme => (
      <nav>
        <div className="brand">
          <Link to="/">
            <SVGImage
              name="logo"
              fill={theme.dark ? '#ececec' : '#81859A'}
              width="56"
            />
          </Link>
        </div>
        <ul className="social">
          <li>
            <a
              href="https://www.linkedin.com/in/dannygiebe"
              title="Linkedin Profile"
              id="li"
            >
              <SVGImage
                name="linkedin"
                fill={theme.dark ? '#ececec' : '#81859A'}
                width="24"
              />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/_giebe"
              title="Follow on twitter"
              id="tw"
            >
              <SVGImage
                name="twitter"
                fill={theme.dark ? '#ececec' : '#81859A'}
                width="24"
              />
            </a>
          </li>
          <li>
            <a
              href="https://dribbble.com/dgiebe"
              title="See work at dribbble"
              id="dr"
            >
              <SVGImage
                name="dribbble"
                fill={theme.dark ? '#ececec' : '#81859A'}
                width="24"
              />
            </a>
          </li>
          <li>
            <a className="dark-switcher" onClick={theme.toggleDark}>
              {theme.dark ? (
                <SVGImage name="sun" fill="#FFB417" width="20" />
              ) : (
                <SVGImage name="moon" fill="#8075FF" width="22" />
              )}
            </a>
          </li>
        </ul>
      </nav>
    )}
  </ThemeContext.Consumer>
)

export default Navigation
