import React from "react"
import { Link } from "gatsby"

const FooterNav = () => (
  
    <ul>
        <li><Link to="/legal/" activeClassName="active">
      Legal
    </Link></li>
        <li><Link to="/privacy/" activeClassName="active">
      Privacy
    </Link></li>
    </ul>
    
    
  
);

export default FooterNav;