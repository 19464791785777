import React from 'react'
import AOS from 'aos'
import PropTypes from 'prop-types'
import Header from './header'
import FooterNav from './footernav'
import './layout.scss'
import '../../node_modules/aos/dist/aos.css'
import ThemeContext from '../context/ThemeContext'

class Layout extends React.Component {
  componentDidMount() {
    AOS.init()
  }
  render() {
    return (
      <ThemeContext.Consumer>
        {theme => (
          <div className={theme.dark ? 'dark' : 'light'}>
            <Header />
            {this.props.children}
            <footer>
              <p> © {new Date().getFullYear()} Danny Giebe</p>
              <FooterNav />
            </footer>
          </div>
        )}
      </ThemeContext.Consumer>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
